import { Component, createEffect, createSignal } from 'solid-js'
import { ToggleProps } from '~/components/fields/toggle/toggle.interfaces'
import styles from '~/components/fields/toggle/toggle.module.scss'
import TogglePill from '~/components/toggle-pill/toggle-pill'
import { PillOption } from '~/components/toggle-pill/toggle-pill.interfaces'
import { fieldId } from '~/services/form/helpers'

const Toggle: Component<ToggleProps> = ({ initialOptions, setValue, label }) => {
  const id = fieldId()

  const [options, setOptions] = createSignal<PillOption[]>(initialOptions)

  createEffect(() => {
    const selectedOption = options().find(entry => entry.checked)
    if(selectedOption){
      setValue(selectedOption.value)
    }
  })

  return (
    <div class={styles.toggle}>
      {label && (
        <label
          for={id}
          class={styles.label}
        >
          {label}
        </label>
      )}
      <TogglePill
        options={options()}
        setOptions={setOptions}
      />
    </div>
  )
}

export default Toggle
