import { Component, createSignal, For, Show } from 'solid-js'
import AttributeLicenseModal from '~/components/attribute-license-modal/attribute-license-modal'
import EmptyState from '~/components/empty-state/empty-state'
import LicenseEntry from '~/components/license-entry/license-entry'
import { LicenseManagerProps } from '~/components/license-manager/license-manager.interfaces'
import styles from '~/components/license-manager/license-manager.module.scss'
import SmallButton from '~/components/small-button/small-button'
import UserIcon from '~/assets/icons/user.svg'
import { translations } from '~/translations'

const LicenseManager: Component<LicenseManagerProps> = ({ licensesGroup, refetch }) => {
  const [showLicenseModal, setLicenseModal] = createSignal(false)
  const onAttribute = () => {
    setLicenseModal(true)
  }

  const emptyState = (
    <EmptyState
      icon={UserIcon}
      label={translations().subscriptions.pro.licenses.emptyState}
    />
  )

  return (
    <div class={styles.licenseManager}>
      <AttributeLicenseModal
        open={showLicenseModal()}
        setOpen={setLicenseModal}
        productId={licensesGroup.plan.productId}
        onSuccess={refetch}
      />
      <header class={styles.head}>
        <div class={styles.title}>
          {translations().subscriptions.pro.licenses.licenses}
        </div>
        <Show
          when={licensesGroup.availableSlots > 0}
          fallback={(
            <div class={styles.emptyState}>
              {translations().subscriptions.pro.licenses.noSlotsAvailable}
            </div>
          )}  
        >
          <SmallButton onClick={onAttribute}>
            {translations().subscriptions.pro.licenses.grant}
          </SmallButton>
        </Show>
      </header>
      <div class={styles.licensesList}>
        <For
          each={licensesGroup.licenses}
          fallback={emptyState}
        >
          {entry => (
            <LicenseEntry
              license={entry}
              onChange={refetch}
            />
          )}
        </For>
      </div>
    </div>
  )
}

export default LicenseManager
