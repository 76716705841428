import { Component } from 'solid-js'
import AttributeLicenseForm from '~/components/attribute-license-form/attribute-license-form'
import { AttributeLicenseModalProps } from '~/components/attribute-license-modal/attribute-license-modal.interfaces'
import Modal from '~/components/modal/modal'

const AttributeLicenseModal: Component<AttributeLicenseModalProps> = ({ open, setOpen, productId, onSuccess }) => {
  const onClose = () => {
    onSuccess()
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Attribuer une license'
    >
      <AttributeLicenseForm
        onSuccess={onClose}
        productId={productId}
      />
    </Modal>
  )
}

export default AttributeLicenseModal
