import { Component, createEffect, createResource } from 'solid-js'
import { AttributeLicenseFormProps, AttributeLicenseSchema, AttributeLicenseType } from '~/components/attribute-license-form/attribute-license-form.interfaces'
import styles from '~/components/attribute-license-form/attribute-license-form.module.scss'
import { createForm, setValue, SubmitHandler, valiForm } from '@modular-forms/solid'
import { getContacts } from '~/services/database/contacts'
import Button from '~/components/button/button'
import FormError from '~/components/form-error/form-error'
import Select from '~/components/fields/select/select'
import { SelectOption } from '~/components/fields/select/select.interfaces'
import { attributeLicense } from '~/services/stripe/license'
import { proUniqueId, uniqueLicenseRoles } from '~/static/stripe'
import Toggle from '~/components/fields/toggle/toggle'

const AttributeLicenseForm: Component<AttributeLicenseFormProps> = ({ productId, onSuccess }) => {
  const isUniqueLicense = productId === proUniqueId
  const [contacts] = createResource(getContacts)
  const contactsAsOptions = () => {
    const list = contacts.latest ?? []
    const options: SelectOption[] = list.map(entry => ({
      label: entry.meta?.name ?? entry.meta?.email ?? entry.user_id,
      value: entry.contact_id
    }))
    return options
  }

  const initialOptions = uniqueLicenseRoles.map((entry, index) => ({
    ...entry,
    checked: index === 0
  }))

  const [form, { Form, Field }] = createForm<AttributeLicenseType>({
    validate: valiForm(AttributeLicenseSchema),
    initialValues: {
      role: initialOptions[0].value
    }
  })

  const setRoleToggle = (value: string) => {
    setValue(form, 'role', value)
  }

  createEffect(() => {
    const list = contactsAsOptions()
    if(list.length > 0){
      setValue(form, 'userId', list[0].value.toString())
    }
  })

  const onSubmit: SubmitHandler<AttributeLicenseType> = async (values) => {
    await attributeLicense({
      licenseType: productId,
      role: isUniqueLicense && values.role ? values.role : null,
      userId: values.userId,
    })
    onSuccess()
  }

  return (
    <Form onSubmit={onSubmit} class={styles.attributeLicenseForm}>
      <div class={styles.columns}>
        <Field name='userId'>
          {(field, props) => (
            <Select
              label='Contact'
              options={contactsAsOptions()}
              {...field}
              {...props}
            />
          )}
        </Field>

        {isUniqueLicense && (
          <Field name='role'>
            {() => (
              <Toggle
                label={'Role'}
                initialOptions={initialOptions}
                setValue={setRoleToggle}
              />
            )}
          </Field>
        )}
      </div>

      <FormError response={form.response} />

      <Button
        type='submit'
        style='lagoon'
        isLoading={form.submitting}
      >
        Attribuer une license
      </Button>
    </Form>
  )
}

export default AttributeLicenseForm
