import { Component } from 'solid-js'
import { LicenseEntryProps } from '~/components/license-entry/license-entry.interfaces'
import styles from '~/components/license-entry/license-entry.module.scss'
import DeleteButton from '~/components/license-entry/delete-button'
import RoleSelector from '~/components/license-entry/role-selector'

const LicenseEntry: Component<LicenseEntryProps> = ({ license, onChange }) => {
  return (
    <div class={styles.licenseEntry}>
      <div class={styles.user}>
        <div class={styles.name}>
          {license.name ?? '-'}
        </div>
        <div class={styles.email}>
          {license.email}
        </div>
      </div>
      <aside class={styles.controls}>
        {license.type === 'unique' && (
          <RoleSelector license={license} />
        )}

        <DeleteButton
          license={license}
          onChange={onChange}
        />
      </aside>
    </div>
  )
}

export default LicenseEntry
