import { Component, createSignal } from 'solid-js'
import { DeleteButtonProps } from '~/components/license-entry/license-entry.interfaces'
import RowButton from '~/components/row-button/row-button'
import DeleteIcon from '~/assets/icons/close.svg'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import { removeLicense } from '~/services/stripe/license'
import { setLoadingState } from '~/services/loading/loading'
import { translations } from '~/translations'

const DeleteButton: Component<DeleteButtonProps> = ({ license, onChange }) => {
  const [showDeleteConfirmation, setDeleteConfirmation] = createSignal(false)
  const showRemoveConfirmation = () => {
    setDeleteConfirmation(true)
  }
  const onDelete = async () => {
    setLoadingState({
      loading: true
    })
    await removeLicense({ licenseId: license.id })
    setDeleteConfirmation(false)
    onChange()
    setLoadingState({
      loading: false
    })
  }

  const deleteConfirmationTexts = {
    title: translations().subscriptions.pro.licenses.deleteConfirmation.title(license.name),
    description: translations().subscriptions.pro.licenses.deleteConfirmation.description,
    confirm: translations().subscriptions.pro.licenses.deleteConfirmation.confirm
  }

  return (
    <>
      <ConfirmationModal
        open={showDeleteConfirmation()}
        setOpen={setDeleteConfirmation}
        onConfirm={onDelete}
        title={deleteConfirmationTexts.title}
        description={deleteConfirmationTexts.description}
        confirmLabel={deleteConfirmationTexts.confirm}
        type='danger'
      />
      
      <RowButton
        icon={DeleteIcon}
        onClick={showRemoveConfirmation}
      />
    </>
  )
}

export default DeleteButton
