import { InferOutput, object, optional, string } from 'valibot'

export const AttributeLicenseSchema = object({
  userId: string(),
  role: optional(string())
})

export type AttributeLicenseType = InferOutput<typeof AttributeLicenseSchema>

export interface AttributeLicenseFormProps {
  onSuccess: () => void
  productId: string
}
