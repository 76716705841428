import { getUserToken } from '~/services/supabase'
import { APIConfirmation } from '~/types/api/response'
import { AttributeLicensePayload, PlanLicenses, RemoveLicensePayload, UpdateLicensePayload } from '~/types/stripe/license'

const attributeLicense = async (payload: AttributeLicensePayload) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/licenses/attribute', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify(payload)
    })
    return await data.json()
  }
}

const removeLicense = async (payload: RemoveLicensePayload) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/licenses/remove', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify(payload)
    })
    return await data.json() as APIConfirmation
  }
}


const updateLicense = async (payload: UpdateLicensePayload) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/licenses/update', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify(payload)
    })
    return await data.json() as APIConfirmation
  }
}

const getLicenses = async () => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/licenses/list', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      }
    })
    return await data.json() as PlanLicenses[]
  }
}

export {
  attributeLicense,
  updateLicense,
  removeLicense,
  getLicenses
}